import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
`
const NavTitle = styled.h1`
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin: 2.2em 1.5em;
`
const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 960px;
  margin: 0em auto;
  padding: 0 1em;
`

const Header = () => (
  <header>
    <Container>
      <NavTitle>
        <StyledLink href="#portfolio">Portfolio</StyledLink>
      </NavTitle>
    
      <NavTitle>
        <StyledLink href="#contact">Contact</StyledLink>
      </NavTitle>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
